/**
 *
 * Custom function
 *
 */

(function($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() { 
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function() {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function() {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function() {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function() {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function() {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function() {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function(e) {
                e.preventDefault();
                
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function() {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function() {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function(event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function() {
                var table = $(this);
                $('.angle-scroll-left').click(function(event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function() {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }

	
	function initializeMap() {

		var locations = new Array();
		var arrayMarker = new Array();
		var infoWindow = new google.maps.InfoWindow();

		var directionsService = new google.maps.DirectionsService();
		var directionsDisplay = new google.maps.DirectionsRenderer();


		//Instazio la mappa centrandola con le coordinate della prima localitò
		var map = new google.maps.Map(document.getElementById('map_canvas'), {
			center: new google.maps.LatLng($(".locations-list li:first-child a").data("location-lat"), $(".locations-list li:first-child a").data("location-lon")),
			zoom: 12,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		});

		//Ciclo il menù con i punti di interesse per leggere gli attributi data dalla DOM
		$(".locations-list li").each(function(){

			var name = ($('a', this).data("location-name"));
			var img = ($('a', this).data("location-image"));
			var lat = ($('a', this).data("location-lat"));
			var lon = ($('a', this).data("location-lon"));
			var index = ($('a', this).data("location-index"));

			//Eventuale icona custom per il marker
			var iconMarket = 'http://cdn1.buuteeq.com/aspx/shared/images/location/Balloon/1/A.png';

			//Creazione contenuto infoWindow (title del post e immagine)
			var content = '<div class="info-window">'+
				'<div class="title">'+name+'</div>'+
				'<img src="'+img+'" alt="'+name+'">'
				'</div>';

			//Creazione del marker per un punto di interesse
			var marker = new google.maps.Marker({
				position: new google.maps.LatLng(lat,lon),
				map: map,
				icon: iconMarket,
				title: name
			});

			//Inserisco il marker appena creato in un vettore da usare successivamente
			arrayMarker[index] = marker;

			//Listener che al click sul marker apre l'infoWindow
			marker.addListener('click', function() {
				infoWindow.setContent(content);
				infoWindow.open(map, marker);
			});

			if($(this).find('a').hasClass('active')){
				
				//Apertura dell'infoWindow rispetto al punto di interesse attivo
				infoWindow.setContent(content);
				infoWindow.open(map, marker);

				//Recupero l'indice della località
				var index = $(this).find('a').data("location-index");
				
				if(index > 0){
					//Cambio il nome del punto di interesse nella DOM
					name =$(this).find('a').data("location-name");
					$("#location-title").html(name);

					//Visualizzo il contenuto testuale del punto di interesse nascondendo tutti gli altri (sono già stampati e resi invisibili)
					$("#info .item").addClass('hide');
					$("#info .item[data-index='"+index+"']").removeClass('hide');

					//Imposto la select per generare il percorso con la partenza dalla località attiva
					$("#directions select[name='from_direction']").prop("selectedIndex", index);
				}
			}

			//Evento al click dell'elenco puntato con i punti di interesse
			$('a', this).click(function(event) {

				//Setto la classe active sulla voce cliccata
				$(".locations-list li a").each(function(){
					$(this).removeClass('active');
				});
				$(this).addClass('active');

				//Mi leggo l'indice della località
				var index = $(this).data("location-index");

				//Mi carico il relativo marker 
				currentMarker = arrayMarker[index];

				//Centro la mappa sul marker
				map.setCenter(currentMarker.getPosition());

				//Apro infoWindow sul marker corrente
				infoWindow.setContent(content);
				infoWindow.open(map, currentMarker);

				//Cambio il nome del punto di interesse nella DOM
				name = $(this).data("location-name");
				$("#location-title").html(name)

				//Visualizzo il contenuto testuale del punto di interesse nascondendo tutti gli altri (sono già stampati e resi invisibili)
				$("#info .item").addClass('hide');
				$("#info .item[data-index='"+index+"']").removeClass('hide');

				//Imposto la select per generare il percorso con la partenza dalla località selezionata
				$("#directions select[name='from_direction']").prop("selectedIndex", index); 

				return false;
			});

		});


		//Gestione freccette di navigazione, controllo l'indice del menù delle località e eseguo il trigger del click sulla voce precedente o successiva
		$(".scrolling.prev").click(function(event) {
			var index = $(".locations-list li a.active").data("location-index");
			if(index == 0){
				index = $(".locations-list li").length-1;
			}else{
				index--;
			}
			$(".locations-list li a[data-location-index='"+index+"']").trigger("click");
		});

		$(".scrolling.next").click(function(event) {
			var index = $(".locations-list li a.active").data("location-index");
			if(index == ($(".locations-list li").length-1)){
				index = 0;
			}else{
				index++;
			}
			$(".locations-list li a[data-location-index='"+index+"']").trigger("click");
		});

		//Gestione del menù per selezionare il tipo di trasporto (Auto, Piedi, Bicicletta o Bus)
		$(".location-slideshow .mode_type").each(function(){
			$(this).click(function(event) {
				var index = $(this).data("index");
				$("#directions .mode_type").removeClass("selected");
				$(this).addClass("selected");
			});
		});

		//Form per stampare il percorso tra i due punti di interesse settati sulle select
		$(".location-slideshow .search-directions").click(function(event) {

			var travel = $(".location-slideshow .mode_type.selected").data("value")

			var from = $("#directions select[name='from_direction']").val().split(",");
			var to = $("#directions select[name='to_direction']").val().split(",");

			var request = {
				origin: new google.maps.LatLng(from[0], from[1]),
				destination: new google.maps.LatLng(to[0], to[1]),
				travelMode: google.maps.DirectionsTravelMode[travel]
			};

			directionsDisplay.setMap(map);
			directionsDisplay.setPanel(document.getElementById('map_route'));

			directionsService.route(request, function(response, status){
				
				if (status == google.maps.DirectionsStatus.OK){
					directionsDisplay.setDirections(response);
				}
				
			});

		});

	}
	
	
    /**
     * Funzione per l'attivazione del menu responsive
     */

    function responsiveMenu() {
        $('.main-navigation').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
            });
        });

        // if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
            var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
            var fixedMenu = $('#js-fixed-menu')[0];

            if (!fixedMenu) return;

            var stickyHeader = new Waypoint.Sticky({
                element: fixedMenu
            });

            $('.main-navigation').find('a[href="#"]').each(function() {
                $(this).click(function(event) {
                    $('ul.sub-menu').not($(this).next()).removeClass('open');
                    $(this).next().toggleClass('open');
                });
            });

            $("#js-menu-offcanvas-button-open").click(function() {
                menuPrimaryOffcanvas.addClass("offcanvas-open");
            });

            $("#js-menu-offcanvas-button-close").click(function() {
                menuPrimaryOffcanvas.removeClass("offcanvas-open");
            });
        // } else {
        //     var stickyHeader = new Waypoint.Sticky({
        //         element: $('#js-header')[0],
        //         // offset: -250 // l'offset è utile se non vuoi far partire subito fisso il menu
        //     });
        // }
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function(index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function(index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };
             
            pswpItems.push(myImage);
        });


        galleryItems.each(function(index) {
            $(this).click(function(evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


    function smoothScroll() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if(target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                    }
                    else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }
                    
                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);
                    
                    return false;
                }
            }
        });
    }

    function startOwl(selector) {
        $(selector).each(function(arguments) {
            var owl = $(this);
            var params = {
                nav : owl.data('owl-navigation'),
                navSpeed : owl.data('owl-slide-speed'),
                autoplay : owl.data('owl-autoplay'),
                autoplayTimeout : owl.data('owl-autoplay-timeout'),
                dots : owl.data('owl-dots'),
                dotsSpeed : owl.data('owl-dots-speed'),
                loop : owl.data('owl-loop'),
                autoHeight : false,
                smartSpeed : owl.data('owl-smart-speed'),
                fluidSpeed : owl.data('owl-fluid-speed'),
                animateOut : owl.data('owl-animate-out'),
                animateIn : owl.data('owl-animate-in'),
                navContainer : owl.data('owl-nav-container'),
                dotsContainer : owl.data('owl-dots-container'),
                mouseDrag : owl.data('owl-mouse-drag'),
                touchDrag : owl.data('owl-touch-drag'),
                slideTransition : owl.data('owl-slide-transition'),
                stagePadding : owl.data('owl-stage-padding'),
                margin : owl.data('owl-margin'),
                lazyLoad : true,
                navText: [ 
                    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="96px" height="44px" viewBox="0 0 96 44" enable-background="new 0 0 96 44" xml:space="preserve">  <image id="image0" width="96" height="44" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAsCAMAAACt3E0dAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABBVBMVEUAAADG0yzH0yzH0i3E0CzG0i3G0yzF0yzH0i2AgACqqgDG0yzG0i3G0i3EzCvH0yzF0i3G0SvH0i3H0i3F0SzH0yzG0i3F0i3G0izD0SrG0yzGxhzG0i3G0SvH0i3H0i3DzyzG0CvG0yzG0iy/vyDH0SvG0i3G0i3B0SfH0i3H0i3E0SvG0ivF0SvH0yzG0i2/zyDG0i3G0i2/vwDG0yzH0ivEzifE0irH0yzH0yzG0i3G0SzHzyvE0Se/yivG0i3F0SPF0ivGzyzH0yzDzivH0yzG0yzH0SzH0yzH0yzG0SvCziTF0yzF0i3G0SzH0SvH0yyZzADH0i3G0i3F0ivH0y3///8NQ8zLAAAAVXRSTlMAYvFbV+7gXN8CA/N4/B75OXDeiGmofnK0N/IJ71mfx0BHz9cITY+9IfT6TlpqhKUQjukEeXYaSez+zpc7JxigFmA6vy+V5nrW/UgVhWFvZOsF9epljUfxhgAAAAFiS0dEVgoN6YkAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnCQ4SMw4mK6vxAAABK0lEQVRYw+2SyVLCQBBAkwBCMHFBkEUQRcVdAVcWZVFBXFDR/v9fsTNewMxUWU73wTJ9fF31Xs1imJYVMggmHJmJShcxgIit74/PAoAj27i4mCM4gQWKwjzyBYLAIigKCcRhgoCxJApJH08hXaYIqApphBmSgKKQRZZbYSzE88gKNAF5YRVRkSggLax5aJ2zUEKysclY2PJImSogK2x7JPpb4Q8KO7sI9vYZCwcecA8ZC0eCHFf4ClVBauYJW+FUEEicnXMVLi6/EvVG02xdXVd0p+0rdFLAMN3JY/Vu6APu9M3d3vWJA7nvj2NnB5n72pDKP3yQ/wH7UfuRn0Tgmejb+2cg/El9kWKcwP8P/CNmv6MvCvyB/w/6X5j9xiuz/23M68crGr9/yPgntH/p/ereJQwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDktMTRUMTY6NTE6MTQrMDI6MDCJo8R6AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA5LTE0VDE2OjUxOjE0KzAyOjAw+P58xgAAAABJRU5ErkJggg==" /></svg>',
                    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="96px" height="44px" viewBox="0 0 96 44" enable-background="new 0 0 96 44" xml:space="preserve">  <image id="image0" width="96" height="44" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAsCAMAAACt3E0dAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABBVBMVEUAAADG0yzH0yzH0i3E0CzG0i3G0yzF0yzH0i2AgACqqgDG0yzG0i3G0i3EzCvH0yzF0i3G0SvH0i3H0i3F0SzH0yzG0i3F0i3G0izD0SrG0yzGxhzG0i3G0SvH0i3H0i3DzyzG0CvG0yzG0iy/vyDH0SvG0i3G0i3B0SfH0i3H0i3E0SvG0ivF0SvH0yzG0i2/zyDG0i3G0i2/vwDG0yzH0ivEzifE0irH0yzH0yzG0i3G0SzHzyvE0Se/yivG0i3F0SPF0ivGzyzH0yzDzivH0yzG0yzH0SzH0yzH0yzG0SvCziTF0yzF0i3G0SzH0SvH0yyZzADH0i3G0i3F0ivH0y3///8NQ8zLAAAAVXRSTlMAYvFbV+7gXN8CA/N4/B75OXDeiGmofnK0N/IJ71mfx0BHz9cITY+9IfT6TlpqhKUQjukEeXYaSez+zpc7JxigFmA6vy+V5nrW/UgVhWFvZOsF9epljUfxhgAAAAFiS0dEVgoN6YkAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnCQ4SMw4mK6vxAAABK0lEQVRYw+2SyVLCQBBAkwBCMHFBkEUQRcVdAVcWZVFBXFDR/v9fsTNewMxUWU73wTJ9fF31Xs1imJYVMggmHJmJShcxgIit74/PAoAj27i4mCM4gQWKwjzyBYLAIigKCcRhgoCxJApJH08hXaYIqApphBmSgKKQRZZbYSzE88gKNAF5YRVRkSggLax5aJ2zUEKysclY2PJImSogK2x7JPpb4Q8KO7sI9vYZCwcecA8ZC0eCHFf4ClVBauYJW+FUEEicnXMVLi6/EvVG02xdXVd0p+0rdFLAMN3JY/Vu6APu9M3d3vWJA7nvj2NnB5n72pDKP3yQ/wH7UfuRn0Tgmejb+2cg/El9kWKcwP8P/CNmv6MvCvyB/w/6X5j9xiuz/23M68crGr9/yPgntH/p/ereJQwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDktMTRUMTY6NTE6MTQrMDI6MDCJo8R6AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA5LTE0VDE2OjUxOjE0KzAyOjAw+P58xgAAAABJRU5ErkJggg==" /></svg>',
                ]
            };

            if (owl.data('owl-single-item')) {
                params['items'] = 1;
            } else {
                params['responsive'] = {
                    0: {
                        items: owl.data('owl-items-xs'),
                    },
                    768: {
                        items: owl.data('owl-items-sm'),
                    },
                    992: {
                        items: owl.data('owl-items-md'),
                    },
                    1200: {
                        items: owl.data('owl-items-lg'),
                    },
                    1440: {
                        items: owl.data('owl-items-xl'),
                    }
                };
            }

            if(owl.data('owl-custom-arrows')) {
                params['navText'] = [
                    '<i class="icon-edt-arrow-left-9">', 
                    '<i class="icon-edt-arrow-right-9">'
                ];
            }

            owl.owlCarousel(params);

            if(owl.data('owl-prev')){
                $(owl.data('owl-prev')).click(function(){
                    owl.trigger('prev.owl.carousel');
                });
            }
            if(owl.data('owl-next')){
                $(owl.data('owl-next')).click(function(){
                    owl.trigger('next.owl.carousel');
                });
            }
        });
    }

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function translateCountdown( langCountdown ) {

        countdown.resetLabels();
        
        if ( langCountdown == 'it' ) {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if ( langCountdown == 'de' ) {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if ( langCountdown == 'fr' ) {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

	function edtCountdown(){        

		jQuery( ".edt_countdown_class" ).find('.item').each( function(){ 
            
		    var dataInizio = "";
		    var questo = jQuery(this);
		    jQuery.post(
			    url.ajax_url, 
			    {
			        'action': 'edita_ajax_get_start_countdown_date',
			        'offerId':   questo.data('offerid'),			        
			    }, 
			    
			    function(response){
					setInterval(function() {
                        stampaCountdown( questo, response );
                    }, 3600);
			    }
			);
	    });
	}

	function stampaCountdown( item, response ){

        jQuery( item ).find( '.pageTimer' ).empty().append(
            moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
        );        
	}

	function closePhotoswipe() {
	
	    $('.pswp__button--close').click(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	
	    $(window).scroll(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	}

    /*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {
		
        var containers = [
            {
                selectFilter: '.select-filters',
                selector: '.mix-filter-support',
                activator: '.first-filter',
                initialFilter: 'all',
                scope: 'global'
            },
            {
                selectFilter: '.select-filters-video',
                selector: '.mix-filter-support-video',
                activator: '.first-filter',
                initialFilter: '.vertical-booking',
                scope: 'global'
            },
            {
                selectFilter: '.select-filters-vacanza',
                selector: '.vacanza-container',
                activator: '.first-filter-vacanza',
                initialFilter: '',
                scope: 'local'
            },
            {
                selectFilter: '.select-filters-esperienze',
                selector: '.esperienze-container',
                activator: '.first-filter-esperienze',
                initialFilter: '',
                scope: 'local'
            }
        ];

        containers.forEach(function(container) {

            console.log(container.selectFilter);

            if ($(container.selector).length) {

                var initialFilter = container.initialFilter;

                if ($(container.activator).length) {
                    $(container.activator).each(function() {
                        var firstFilter = $(this).children(':first-child').attr('class').split(' ')[0];
                        initialFilter = '.' + firstFilter;
                    });
                }

                if (initialFilter === '' && $(container.selector).data('initial-filter')) {
                    initialFilter = '.' + $(container.selector).data('initial-filter');
                }

                var config = {
                    selectors : {
                        control : '[data-mixitup-control]'
                    },
                    load: {
                        filter: initialFilter
                    },
                    controls: {
                        scope: container.scope
                    }
                };

                var mixer = mixitup(container.selector, config);

                $(container.selectFilter).on('change', function() {
                    console.log('change!');
                    mixer.filter(this.value);
                });

            }

        });

        $('.item-filter button').each(function() {
            $(this).on('click', function() {
                if (!$(this).hasClass('active')) {
                    $(this).parent().find('.filter.active').removeClass('active');
                }
            });
        });

    }

    /**
     * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
     * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
     * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
     * ritorna un boleano.
     */
    var mapsMobileUrl = {
        isAndroid: function() {
            return /(android)/i.test(navigator.userAgent);
        },
        isIos: function() {
            return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
        }
    };


    function injectorLinkMaps()
    {
        var buttonMaps = $('#js-fixed-menu').find('.navigator');

        if ( mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'href', phpMapsMobileUrl.android );

        if ( mapsMobileUrl.isIos() ) buttonMaps.attr( 'href', phpMapsMobileUrl.ios );

        if ( ! mapsMobileUrl.isAndroid() && ! mapsMobileUrl.isIos() ) buttonMaps.hide();
    }


    function slideMobile() {
        if (!$('body').hasClass('is-mobile')) return;

        var itemcount = 1;

		if( typeof mobileSize !=='undefined' ) {

	        $.each(mobileSize, function (index, item) {
	
	            if ($(window).width() > 767) {
	                $('.header-slideshow .itemcounter-' + itemcount + ' ' + 'img').attr({
	                    src: item[0]['tablet'][0],
	                    width: item[0]['tablet'][1],
	                    height: item[0]['tablet'][2],
	                });
	            } else {
	                $('.header-slideshow .itemcounter-' + itemcount + ' ' + 'img').attr({
	                    src: item[0]['mobile'][0],
	                    width: item[0]['mobile'][1],
	                    height: item[0]['mobile'][2],
	                });
	            }
	
	            itemcount++;
	        });
	     }
    }
	
	
	function getUrlVars() {
	    
	    var vars = [], hash;
	    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	    for(var i = 0; i < hashes.length; i++)
	    {
	        hash = hashes[i].split('=');
	        vars.push(hash[0]);
	        vars[hash[0]] = hash[1];
	    }
	    return vars;
	}

    function accordion() {
        var accItem = document.getElementsByClassName("accordionItem");
        var accHD = document.getElementsByClassName("accordionItemHeading");

        if (accItem.length == 0) return;

        for (i = 0; i < accHD.length; i++) {
            accHD[i].addEventListener("click", toggleItem, false);
        }
        
        function toggleItem() {
            var itemClass = this.parentNode.className;
            for (i = 0; i < accItem.length; i++) {
                accItem[i].className = "accordionItem closeItem";
            }
            if (itemClass == "accordionItem closeItem") {
                this.parentNode.className = "accordionItem openItem";
            }

            var headerHeight = $('#js-header').outerHeight() + 50;

            $('html, body').animate({
                scrollTop: $(this).offset().top - headerHeight,
            }, 500);
        }
    }

    function verticalScroller() {
        var container = $('.cosa-facciamo');

        if(container.length == 0 || $(window).width() < 1024) {
            return;
        }

        var tsArray = [];
        var owl = $('.scroller.owl-carousel');
        var isScrollActive = true; // Variabile di stato
        var isGoingUp = false;
        var headerHeight = $('#js-header').outerHeight();

        const aboutUsObserver = new IntersectionObserver( function(entries, observer) {
            entries.forEach( function(entry) {
                const targetElement = document.querySelector(".cosa-facciamo");

                if(entry.isIntersecting) {
                    const targetElementTop = targetElement.getBoundingClientRect().top - headerHeight;
                    $('html,body').animate({scrollTop:window.scrollY + targetElementTop},500);
                    
                    targetElement.classList.add('elem-center');
                    $('body, html').addClass('no-scrollbar');
                }
                else {
                    targetElement.classList.remove('elem-center');
                    $('body, html').removeClass('no-scrollbar');
                }
            });
        }, { threshold: 0.3});

        aboutUsObserver.observe(document.querySelector(".cosa-facciamo"));

        $(window).on( 'scroll load', function(e){ 

            if(container.hasClass('elem-center')) {

                $(window).on('mousewheel', function (e) {
                    $('body, html').addClass('no-scrollbar');
                    var timeStamp = e.timeStamp;
                    var prevTimeStamp = tsArray[0];
                    var differenzaInMillisecondi = timeStamp - prevTimeStamp;
                    var personaContainer = $('.persona-container');
                    var visibleImg = $(personaContainer).find('img.visible');
                    var nextImg = visibleImg.next('img');
                    var prevImg = visibleImg.prev('img');

                    if (e.originalEvent.wheelDelta > 0) {
                        if(differenzaInMillisecondi > 50) {
                            setTimeout(function() { 
                                owl.trigger('prev.owl');

                                if(prevImg.length) {
                                    visibleImg.removeClass('visible');
                                    prevImg.addClass('visible');
                                }
                            }, 900);
                        }    
                    } else {
                        if(differenzaInMillisecondi > 50) {
                            setTimeout(function() {
                                owl.trigger('next.owl');

                                if(nextImg.length) {
                                    visibleImg.removeClass('visible');
                                    nextImg.addClass('visible');
                                }

                            }, 900);
                        }
                    }
                    
                    tsArray[0] = timeStamp;

                    owl.on('changed.owl.carousel', function(e) {

                        var currentSlide = e.item.index; // Ottieni l'indice della slide attiva
        
                        if (currentSlide === 0) {
                            setTimeout(function() { 
                                isScrollActive = false;
                                isGoingUp = true;
                                $(container).removeClass('elem-center');
                                $('body, html').removeClass('no-scrollbar');
                            }, 1000);
                        }
  
                        if (e.namespace && e.property.name === 'position' 
                            && e.relatedTarget.relative(e.property.value) === e.relatedTarget.items().length - 1) {
                            setTimeout(function() { 
                                isScrollActive = false;
                                $(container).removeClass('elem-center');
                                $('body, html').removeClass('no-scrollbar');
                            }, 1000);
                        }
                    })
                });
            }
             else {
                $('body, html').removeClass('no-scrollbar');
                isGoingUp = false;
                $(window).unbind('mousewheel');
            }

            if(!isGoingUp) {
                var currentScrollTop = $(this).scrollTop();
                if (currentScrollTop < lastScrollTop){
                    isScrollActive = true;
                }

                lastScrollTop = currentScrollTop;
            }          
        });
    }

    function global_responsiveScrollingTables(selector) {

        function sideScroll(element, direction, speed, distance, step) {
            scrollAmount = 0;
            var slideTimer = setInterval(function () {
                if (direction == 'left') {
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if (scrollAmount >= distance) {
                    window.clearInterval(slideTimer);
                }
            }, speed);
        }

        /*  
        *   Sostituisco tutti i tag di ogni tabella con tag <div>,
        *   aggiungo la classe .table-responsive al <div> che sostituisce il tag <table>,
        *   wrappo ogni tabella in un <div> con classe .table-responsive-container    
        */

        var tables = document.querySelectorAll(selector);
        var openingTags = /(<table\s?[^>]*>|<tr\s?[^>]*>|<td\s?[^>]*>|<th\s?[^>]*>|<tbody\s?[^>]*>)/ig;
        var closingTags = /(<\/table\s?[^>]*>|<\/tr\s?[^>]*>|<\/td\s?[^>]*>|<\/th\s?[^>]*>|<\/tbody\s?[^>]*>)/ig;

        var openingTd = /(<td\s?[^>]*>)/ig;
        var closingTd = /(<\/td\s?[^>]*>)/ig;

        var openTagsToRemove = /(<thead\s?[^>]*>)/ig;
        var closeTagsToRemove = /(<\/thead\s?[^>]*>)/ig;

        for (i = 0; i < tables.length; i++) {

            var customClass = tables[i].className;

            /*
            *   Se la tabella ha un tag <thead>, lo rimuovo e lo reinserisco convertito in div
            */

            if (tables[i].getElementsByTagName('thead')[0]) {
                var theadContent = tables[i].getElementsByTagName('thead')[0].innerHTML;
                var thead = theadContent.replace(openTagsToRemove, '').replace(closeTagsToRemove, '').replace(openingTags, '<div>').replace(closingTags, '</div>');
                var theadFrag = document.createRange().createContextualFragment(thead);
                tables[i].removeChild(tables[i].getElementsByTagName('thead')[0]);
            }

            var divTable = tables[i].outerHTML.replace(openingTd, '<td><p>').replace(closingTd, '</p></td>').replace(openingTags, '<div>').replace(closingTags, '</div>');
            var htmlObject = document.createElement('div');
            htmlObject.innerHTML = divTable;
            htmlObject.children[0].classList.add('edt-table-responsive-container');
            htmlObject.children[0].children[0].classList.add('edt-table-responsive');

            if (customClass) {
                htmlObject.children[0].children[0].classList.add(customClass);
            }

            if (theadFrag) {
                htmlObject.children[0].children[0].insertBefore(theadFrag, htmlObject.children[0].children[0].firstChild);
            }

            tables[i].outerHTML = htmlObject.innerHTML;
        }

        if (window.innerWidth < 1200) {
            var newTables = document.querySelectorAll('.edt-table-responsive');

            for (i = 0; i < newTables.length; i++) {

                /*  
                *   Separo la prima colonna che dovrà restare fissa
                */

                var childrenCount = newTables[i].childElementCount;

                var firstColumn = document.createElement('div');

                for (n = 0; n < childrenCount; n++) {
                    var itm = newTables[i].children[n].children[0];
                    var cln = itm.cloneNode(true);
                    firstColumn.appendChild(cln);
                    itm.remove();
                }

                var objFirstCol = document.createElement('div');
                var parentObjFirstCol = document.createElement('div');
                objFirstCol.innerHTML = firstColumn.innerHTML;
                objFirstCol.children[0].parentNode.classList.add('edt-table-responsive', 'firstcolumn');

                parentObjFirstCol.classList.add('edt-table-responsive-container', 'firstcolumn-container');
                newTables[i].parentNode.before(parentObjFirstCol);
                parentObjFirstCol.appendChild(objFirstCol);

                /*
                *   Creo un wrapper ulteriore con classe .table-responsive-wrapper,
                *   che conterrà la nuova tabella generata.
                *   Creo anche il <div> per le frecce direzionali che attivano lo scroll al touch.
                */

                var wrapperElem = document.createElement('div');
                wrapperElem.classList.add('edt-table-responsive-wrapper');
                parentObjFirstCol.parentNode.insertBefore(wrapperElem, parentObjFirstCol);

                var tableCln = newTables[i].parentNode.cloneNode(true);
                var firstColumnCln = parentObjFirstCol.cloneNode(true);
                wrapperElem.appendChild(firstColumnCln);
                wrapperElem.appendChild(tableCln);

                newTables[i].parentNode.remove();
                parentObjFirstCol.remove();

                var cols = newTables[i].children[0].childElementCount;


                var scrollCmd = document.createElement('div');

                scrollCmd.className = 'scroll-commands';
                if (cols > 1) {
                    scrollCmd.innerHTML = '<div class="colprev disabled"><i class="icon-edt-arrow-left-9"></i></div>';
                    scrollCmd.innerHTML += '<div class="colnext"><i class="icon-edt-arrow-right-9"></i></div>';
                }
                wrapperElem.prepend(scrollCmd);

            }


            var resTables = document.querySelectorAll('.edt-table-responsive-wrapper');

            for (i = 0; i < resTables.length; i++) {
                var parTable = resTables[i].querySelector('.edt-table-responsive-container:not(.firstcolumn-container)');
                var firstCol = resTables[i].querySelector('.edt-table-responsive-container .firstcolumn');
                var cmdContainer = resTables[i].querySelector('.scroll-commands');

                /*
                *   Calcolo e assegno la larghezza della tabella in percentuale,
                *   ogni colonna è larga 50%
                */

                var colsCount = parTable.children[0].children[0].childElementCount;
                cmdContainer.setAttribute('data-cols', colsCount);

                //if (colsCount > 1) {
                var tableWidthPercentage = 50 * colsCount + '%';
                parTable.children[0].style.width = tableWidthPercentage;
                // }

                /*
                *   Equalizzo l'altezza di ogni cella
                */

                var childrenCount = parTable.children[0].childElementCount;

                for (n = 0; n < childrenCount; n++) {
                    var el = parTable.children[0].children[n];

                    if (firstCol.children[n].offsetHeight <= el.offsetHeight) {
                        firstCol.children[n].style.height = el.offsetHeight + 'px';
                        el.style.height = el.offsetHeight + 'px';
                    }
                    else {
                        firstCol.children[n].style.height = firstCol.children[n].offsetHeight + 'px';
                        el.style.height = firstCol.children[n].offsetHeight + 'px';
                    }
                }

                /*
                *   Calcolo di quanti pixel deve scrollare la tabella ad ogni input 
                *   e assegno l'evento click ai bottoni prev e next
                */

                var parTableWidth = parTable.offsetWidth;
                var tableWidthPx = parTableWidth * (parseInt(tableWidthPercentage) / 100);

                var pxToScroll = (tableWidthPx / colsCount);

                var btnPrev = resTables[i].querySelector('.colprev');
                var btnNext = resTables[i].querySelector('.colnext');

                console.log(btnPrev);

                if (btnPrev && btnNext) {
                    [btnPrev, btnNext].forEach( function(item) {
                        item.addEventListener('click', function(event) {
                            var scrollTarget = item.parentNode.parentNode.querySelector('.edt-table-responsive-container:not(.firstcolumn-container)');
                            var prev = item.previousElementSibling;
                            var next = item.nextElementSibling;
                            var scrollRight = scrollTarget.scrollLeft + pxToScroll;
                            var firstColPos = scrollTarget.children[0].children[0].children[0].getBoundingClientRect();
                            var finish = scrollTarget.children[0].offsetWidth - pxToScroll;

                            if (prev !== null) {
                                sideScroll(scrollTarget, 'right', 5, pxToScroll, 5);

                                prev.classList.remove('disabled');

                                if (scrollRight >= finish) {
                                    item.classList.add('disabled');
                                }
                            }

                            if (next !== null) {
                                sideScroll(scrollTarget, 'left', 5, pxToScroll, 5);

                                next.classList.remove('disabled');

                                if (Math.sign(firstColPos.left) == 1) {
                                    item.classList.add('disabled');
                                }
                            }
                        })
                    })
                }

                /*
                *   Gestisco lo scroll dato dal touch del dito 
                */

                var touchstartX = 0;
                var touchendX = 0;
                //var touchstartY = 0;
                //var touchendY = 0;

                var gestureZone = parTable;

                gestureZone.addEventListener('touchstart', function (event) {
                    touchstartX = event.changedTouches[0].screenX;
                    touchstartY = event.changedTouches[0].screenY;
                }, false);

                gestureZone.addEventListener('touchend', function (event) {
                    touchendX = event.changedTouches[0].screenX;
                    touchendY = event.changedTouches[0].screenY;
                    handleGesture(this);
                }, false);

                function handleGesture(target) {
                    var lastColPos = target.children[0].children[0].lastElementChild.getBoundingClientRect();
                    var prev = target.parentNode.children[0].querySelector('.colprev');
                    var next = target.parentNode.children[0].querySelector('.colnext');

                    if (prev !== null && next !== null) {

                        if (touchendX <= touchstartX) {
                            // console.log('Swiped left');
                            var diffLeft = touchstartX - touchendX;
                            sideScroll(target, 'right', 5, pxToScroll - diffLeft, 5);

                            prev.classList.remove('disabled');

                            if (target.scrollLeft >= lastColPos.left) {
                                next.classList.add('disabled');
                            }
                        }

                        if (touchendX >= touchstartX) {
                            // console.log('Swiped right');
                            var diffRight = touchendX - touchstartX;
                            sideScroll(target, 'left', 5, pxToScroll - diffRight, 5);

                            next.classList.remove('disabled');

                            if (target.scrollLeft == 0) {
                                prev.classList.add('disabled');
                            }
                        }

                    }

                    // if (touchendY <= touchstartY) {
                    //     console.log('Swiped up');
                    // }

                    // if (touchendY >= touchstartY) {
                    //     console.log('Swiped down');
                    // }

                    // if (touchendY === touchstartY) {
                    //     console.log('Tap');
                    // }
                }
            }
        }
    }

    $j(document).ready(function() {
        allClick();
        // responsiveTable();
        addImageSizeForPhotoSwiper();
        iframeModalOpen();
        responsiveMenu();
        filterElements();
        injectorLinkMaps();
        slideMobile();
        accordion();
        
        startOwl('.owl-carousel');
        smoothScroll();
        
        verticalScroller();

		closePhotoswipe();

        if ( typeof lang && lang != 'en' ) {
            translateCountdown( lang );
		}

        edtCountdown();

        var secondaryTextarea = $('.secondary-textarea .entry-content');

        if(secondaryTextarea) {
            $(secondaryTextarea).each( function() {
                var prevElem = $(this).find('h2').prev();
                
                if(prevElem.is('h2')) {
                    prevElem.addClass('no-arrow');
                }
            });
        }

        $('.gform_wrapper form').each(function() {

            $(this).checkEditaForm();
        });

		
        $('.checkNewsletter').each(function() {
            $(this).checkEditaForm();
        });
        
        $('#menu-toggle').click(function() {

			$(this).toggleClass('open');
	        $('body').toggleClass('menu-opened');
	        
	        if($(this).hasClass('open')) {
		        
		        $('#main-menu').fadeIn('fast');
		        
	        } else {
		        
		        $('#main-menu').fadeOut('fast');
	        }
        });
        
        
        //note(@duncanmid): feedback pager
		
		//if( !$('body').hasClass('is-mobile') ) {
		
			if( $('.offers-slideshow .owl-nav').length > 0 ) {
				
				$('.offers-slideshow .owl-nav').appendTo('#offers-nav');
			}
		//}
		
		// match heights
		
		$('.offer-inner a').matchHeight();
		$('.offer-description').matchHeight();
		$('.offer-price').matchHeight();

        var selectActivities = $('select.select-activities');

        if(selectActivities) {
            console.log(selectActivities);
            
            $(selectActivities).each(function() {
                $(this).on('change', function() {
                    if($(this).val() == 'altro') {
                        $('.edt-hidden-field').show();
                    }
                    else {
                        $('.edt-hidden-field').hide();
                    }
                });
            });
        }		
		
		if( $( '#richiesta-download-ebook-27' ).length > 0 ) {

            function decodeUrlComponentWithPlus(str) {
                return decodeURIComponent(str.replace(/\+/g, ' '));
            }
			
			var ebookTitle = decodeUrlComponentWithPlus(getUrlVars()['ebook']);
			
			if( ebookTitle ) {
                $('#richiesta-download-ebook-27 input[type="checkbox"]').each( function() {
                    var val = $(this).val();

                    if(ebookTitle == val) {
                        $(this).attr('checked', true);
                    }
                });				
			}
		}

        $('.services-link').each( function() {
            $(this).on('click', function() {
                var popup = $(this).closest('.camera-text').find('.services-popup');
                var container = $(this).closest('article');
                popup.addClass('open');
                container.addClass('open');

                if($('body').is('.is-mobile')) {
                    $('body').addClass('open-popup');
                }
            });
        });

        $('.close-popup').each( function() {
            $(this).on('click', function() {
                var popup = $(this).parent();
                var container = $(this).closest('article');
                popup.removeClass('open');
                container.removeClass('open');

                if($('body').is('.is-mobile')) {
                    $('body').removeClass('open-popup');
                }
            });
        });

        if($(window).width() > 1024) {
            $('.case-history .tab').click(function () {
                $('.case-history .tab').removeClass('active');
                var tabIndex = $(this).data('tab-index');
                $(this).addClass('active');
                $('.cases-gallery').trigger('to.owl.carousel', [tabIndex, 300]);
            });
        }
        else {
            var casesCarousel = $('.cases-gallery');
            var casesTab = $('.case-history .tab-navigation');

            casesTab.on('change', function () {
                var tabIndex = $(this).val();
                casesCarousel.trigger('to.owl.carousel', [tabIndex, 300]);
            });

            casesCarousel.on('changed.owl.carousel', function(e) {
                var index = e.item.index;
                casesTab.val(index);
            });
        }


        $('main .entry-content iframe').each( function() {
            $(this).wrap('<div class="rwd-video"></div>');
        });

        var softwareCheckbox = $('#efb-545-2');

        if(softwareCheckbox) {
            $(softwareCheckbox).on('change', function() {
                if($(this).is(':checked')) {
                    $('#item-efb-546').show();
                }
                else {
                    $('#item-efb-546').hide();
                }
            });
        }
    });


    $j(window).load(function() {

        global_responsiveScrollingTables('.entry-content table');

        const item = document.querySelector(".cosa-facciamo");
        if(item && item.getBoundingClientRect().bottom <= 0) {
            var owl = $('.scroller.owl-carousel');
            owl.trigger('to.owl.carousel', 2);
        }

        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */
        if ( $('body').hasClass('page-template-template-mappa') ) {
            
            $.getScript('https://www.google.com/jsapi', function() {            
                if(url.key) {
                    var map_params = 'language=' + url.lingua + '&key=' + url.key;
                }
                else {
                    var map_params = 'language=' + url.lingua;
                }
                
                google.load('maps', '3', {
                    other_params: map_params + '&libraries=places',
                    callback: function() {
                        initializeGmap();
                    }
                });
            });
        }

        var gallery = $('.gallery');

        if ( gallery.length ) {
            console.log('there is gallery');

            gallery.each(function( index ) {
                
                initPhotoSwiper( "#" + $( this ).attr('id') + " .gallery-item");
            });
        }

        initPhotoSwiper(".entry-content .single-image");

        $('.section-form .form-group').equalHeights();

        if($(window).width() > 1024) {
            $('.page-blocks .image').equalHeights();
            $('section.eventi.template .content').equalHeights();
            $('section.eventi.template .item .title').equalHeights();
            $('body.single-software .feedback-container .item').equalHeights();
        }
        
        $.getScript('https://www.google.com/jsapi', function() {
			google.load('maps', '3', {
				other_params: 'language=it&key=' + url.key,
				callback: function() {
					initializeGmap();
					if ($("body").hasClass("page-template-template-locations")) {

						initializeMap();

						$(".owl-scrolling-container .prev").click(function(){
							var index = $(".locations-list li").find("a.active").attr("data-location-index");
							if(index == 0){
								index = $(".locations-list li").length-1;
							}else{
								index--;
							}
							console.log(index)
							$(".locations-list li").find("[data-location-index='" + index + "']").trigger('click');
						});
						$(".owl-scrolling-container .next").click(function(){
							var index = $(".locations-list li").find("a.active").attr("data-location-index");
							if(index == ($(".locations-list li").length-1)){
								index = 0;
							}else{
								index++;
							}
							console.log(index)
							$(".locations-list li").find("[data-location-index='" + index + "']").trigger('click');
						});
					}
				}
			});
		});
    });

    $j(window).scroll(function(e) {
        if ($(this).scrollTop() > 0) {
            $('#js-header').addClass('scroll');
            $('.main-menu-inner > img').hide();
        } else {
            $('#js-header').removeClass('scroll');
            $('.main-menu-inner > img').show();
        }
    });

    $j(window).resize(function() {});

})(jQuery);
